import React from "react"

import Layoutde from "../../components/layoutde/layoutde"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../../components/footer/footer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMouse, faSearch, faCartPlus, faCheck, faCalendar, faMobile, faStarOfLife, faInfinity, faCode, faPlug, faStar, faUsers, faCircleNotch, faChartBar, faLightbulb, faPollH, faFileImage, faServer } from '@fortawesome/free-solid-svg-icons'
import { faTrello, faAmazon } from '@fortawesome/free-brands-svg-icons'

import "../index.scss"
import "../our-work.scss"

import ImgAppsLogo1 from "../../images/apps/logo-feedtrack-color.svg"
import ImgAppsLogo2 from "../../images/apps/logo-ecim-color.svg"
import ImgAppsLogo3 from "../../images/apps/logo-spring-color.svg"
import ImgAppsLogo4 from "../../images/apps/logo-dmonitor-black.svg"

import ImgAppsFeedtrackMockup1 from "../../images/apps/apps-feedtrack-mockup-1.png"
import ImgAppsFeedtrackMockup2 from "../../images/apps/apps-feedtrack-mockup-2.png"
import ImgAppsFeedtrackMockup3 from "../../images/apps/apps-feedtrack-mockup-3.png"
import VideoAppFeedtrack from "../../videos/ftrs-app-feedtrack.mp4"

import ImgAppsSpringMockup1 from "../../images/apps/apps-spring-mockup-1.png"
import ImgAppsSpringMockup2 from "../../images/apps/apps-spring-mockup-2.png"
import ImgAppsSpringMockup3 from "../../images/apps/apps-spring-mockup-3.png"
import ImgAppsSpringMockup4 from "../../images/apps/apps-spring-mockup-4.jpg"

import ImgAppsEcimMockup1 from "../../images/apps/apps-ecim-mockup-1.jpg"
import ImgAppsEcimMockup2 from "../../images/apps/apps-ecim-mockup-2.jpg"
import ImgAppsEcimMockup3 from "../../images/apps/apps-ecim-mockup-3.jpg"
import ImgAppsEcimMockup4 from "../../images/apps/apps-ecim-mockup-4.jpg"

import ImgAppsDmonitorMockup1 from "../../images/apps/apps-dmonitor-mockup-1.png"
import ImgAppsDmonitorMockup2 from "../../images/apps/apps-dmonitor-mockup-2.png"
import ImgAppsDmonitorMockup3 from "../../images/apps/apps-dmonitor-mockup-3.png"
import ImgAppsDmonitorMockup4 from "../../images/apps/apps-dmonitor-mockup-4.png"

import VideoBg from "../../videos/ftrs-video.mp4"

import VideoApp1 from "../../videos/ftrs-videoapp1.mp4"
import VideoApp2 from "../../videos/ftrs-videoapp2.mp4"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"

const Referenzen = () => (

    <Layoutde>
        <SEO title="Referenzen" description="Unsere Web- und mobilen Apps können sich sehen lassen" />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-our-work">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Unsere Web- und&nbsp;mobile Apps können sich sehen&nbsp;lassen</h1>

            <Row>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Im Lauf unserer langjährigen Tätigkeit und&nbsp;dank der&nbsp;daraus resultierenden Erfahrung konnten wir bereits zahlreiche smarte und&nbsp;moderne Tools entwickeln, die&nbsp;Nutzern viel Arbeit ersparen und&nbsp;extrem anwenderfreundlich sind.</p>
                </Col>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">Wir entwickeln, warten und&nbsp;verbessern seit&nbsp;vielen Jahren Apps für&nbsp;mittelständische und&nbsp;große Unternehmen aus&nbsp;den&nbsp;Bereichen Finanzen, Versicherungen, Automobil, Telekommunikation und&nbsp;anderen Branchen. Unsere Produkte sind in&nbsp;der&nbsp;Regel in&nbsp;die&nbsp;grundlegenden Unternehmensprozesse eingebunden und&nbsp;erfordern daher einen sehr sorgfältigen und&nbsp;verantwortungsvollen Zugang.</p>
                </Col>
            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h3 ftrs-our-work__h3">Hier einige Beispiele unserer Arbeit:</p>

        </Container>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--feedtrack"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo1} alt="feedTRACK" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--feedtrack" data-sal="fade" data-sal-duration="1000"># Kundenerfahrung</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle">Ein intelligentes Tool zur Verwaltung von Kundenerfahrungswerten, das&nbsp;einfach und&nbsp;schnell Kundenfeedback erhebt und&nbsp;umgehendes Feedback liefert.</h2>
								<ul className="ftrs-app__description">
									<li>Intelligente E-Mail-Marketing-Lösung</li>
									<li>Skalierbare Projekte</li>
									<li>Sofortige Feedback-Verarbeitung</li>
									<li>Einfache Analyse und&nbsp;Berichtsgenerierung</li>
									<li>Sprachliche Lokalisierung</li>
									<li>Aufgabenmanagement</li>
									<li>Benutzerzugriffsverwaltung</li>
									<li>Benachrichtigung</li>
									<li>Automatisierung des&nbsp;Datenimports</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">Projektbeschreibung:</h2>
						Als die Menge der verarbeiteten Daten und&nbsp;die&nbsp;Vielfalt der&nbsp;Projekte eine bestimmte Menge überschritten hatten, war Cloud-Migration die&nbsp;richtige Entscheidung.
						Die&nbsp;Cloud ermöglicht uns die&nbsp;Leistung unserer Apps sofort zu&nbsp;skalieren, ohne dass wir sie ständig beaufsichtigen müssen.
						Das&nbsp;Ziel dieses Projekts war es, feedTRACK für&nbsp;höhere Daten-Volumen vorzubereiten.
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__feedtrack-header">Kundenbewertung:</div>
						<div className="ftrs-app__feedtrack-stars">
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
						</div>

						<p className="ftrs-app__feedtrack-review">
							Wir waren hier das&nbsp;erste Mal, und&nbsp;kommen bestimmt wieder! Die&nbsp;Insel war unglaublich, die&nbsp;Farben, der&nbsp;Ozean und&nbsp;das&nbsp;Unterwasserleben waren wunderschön.
						</p>
						<p className="ftrs-app__feedtrack-review">
							Das Personal war freundlich und&nbsp;hilfsbereit, vielen Dank für&nbsp;Ihre Umsichtigkeit. Essen war fantastisch, Dank an&nbsp;den&nbsp;Chefkoch.
						</p>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">Unterkunft</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">Essen</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">Zeitvertreib</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star ftrs-app__feedtrack-small-star--gray" />
							</div>
							<div className="ftrs-app__feedtrack-category">Fahrt und&nbsp;Flug</div>
						</div>

					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp1} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">unser Beitrag:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							Projektleitung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							Api von&nbsp;Drittanbietern
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faAmazon} />
							</div>
							Migration in&nbsp;die&nbsp;Cloud
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							Design und Architektur
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faServer} />
							</div>
							DevOps
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							Qualitätskontrolle
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							Unterstützung und&nbsp;Wartung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faCode} />
							</div>
							präzise SW&nbsp;Entwicklung
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup1} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup2} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup3} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">2.200.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">jährlich erreichte Kunden</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCircleNotch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">bis zu 30 %</div>
							<div className="ftrs-app__feedtrack-point-description">Rücklaufquote</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faChartBar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">+ 67</div>
							<div className="ftrs-app__feedtrack-point-description">durchschnittlicher NPS</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faLightbulb} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">66000 +</div>
							<div className="ftrs-app__feedtrack-point-description">Verbesserungsvorschläge jährlich</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<video autoPlay="autoplay" muted loop className="ftrs-app__macbook-video">
								<source src={VideoAppFeedtrack} type="video/mp4" />
							</video>
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--spring"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo3} alt="Spring" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--spring" data-sal="fade" data-sal-duration="1000"># Marktforschung</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle mb1">Ein modernes Mystery-Shopping-Tool zur&nbsp;Verwaltung von&nbsp;Testkaufprojekten.</h2>
								<ul className="ftrs-app__description mt1 mb3">
									<li>Web- und mobile Datenerfassungs-App</li>
									<li>Umgehende Berichtsvorlage</li>
									<li>GPS-Standortbestimmung</li>
									<li>Stellenausschreibungen</li>
									<li>Aufgabenmanagement</li>
									<li>Mediengalerie</li>
									<li>Tool zur Erstellung von&nbsp;Umfragen</li>
									<li>Vergütungsmanagement</li>
									<li>Modul zur&nbsp;Qualitätskontrolle</li>
									<li>Datenimport und -export</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">Projektbeschreibung:</h2>
						<p>Dieses Projekt begann vor&nbsp;etwa 15&nbsp;Jahren mit&nbsp;dem&nbsp;Ansatz, die&nbsp;absoluten Grundlagen der&nbsp;Mystery-Shopping-Branche zu&nbsp;automatisieren.</p>
						<p>Seitdem haben wir es zu&nbsp;einem System ausgebaut, das die&nbsp;Abläufe, die&nbsp;Kommunikation und&nbsp;das&nbsp;Reporting automatisiert. Obwohl das&nbsp;System bereits zahlreiche Funktionen hat, werden in&nbsp;jedem Entwicklungsschritt neue Funktionen hinzugefügt.</p>
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__spring-question">Hatten Sie das&nbsp;Gefühl, dass der&nbsp;Verkäufer aufrichtig an&nbsp;Ihnen interessiert war?</div>
						<div className="ftrs-app__spring-scale">
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								Ja
							</div>
							<div className="ftrs-app__spring-scale-item ftrs-app__spring-scale-item--active">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox ftrs-app__spring-checkbox--active" />
								Eher ja
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								Neutral
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								Eher nein
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								Nein
							</div>
						</div>
					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp2} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">unser Beitrag:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							Projektleitung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							Design und Architektur
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faCode} />
							</div>
							präzise SW&nbsp;Entwicklung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faServer} />
							</div>
							DevOps
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faMobile} />
							</div>
							Mobile App
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							Api von&nbsp;Drittanbietern
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							Qualitätskontrolle
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							Unterstützung und&nbsp;Wartung
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup1} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup2} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup3} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCartPlus} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">500.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">Mystery Shopping durchgeführt</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">10.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">Benutzer</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCheck} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">40.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">ausgefüllte Formulare jährlich</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">20 +</div>
							<div className="ftrs-app__feedtrack-point-description">Jahre im&nbsp;Betrieb</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsSpringMockup4} alt="Spring" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--ecim"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo2} alt="eCIM" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--ecim" data-sal="fade" data-sal-duration="1000"># market intelligence</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Branchenführende Plattform, die&nbsp;multinationalen Unternehmen hilft, immer einen Schritt vor&nbsp;ihren Mitbewerbern zu&nbsp;sein.</h2>
						<ul className="ftrs-app__description">
							<li>Nachrichten</li>
							<li>Videos</li>
							<li>Soziale Netzwerke</li>
							<li>Bilder</li>
							<li>Analysen</li>
							<li>E-Mail-Benachrichtigungen</li>
							<li>Newsletter</li>
							<li>Daten und Grafiken</li>
							<li>Benutzerverwaltung</li>
							<li>Volltextsuche</li>
							<li>Inhaltskategorisierung</li>
							<li>Nutzungsstatistiken</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">Projektbeschreibung:</h2>
						<p>Das Ziel von&nbsp;eCIM war es, eine&nbsp;Plattform zu&nbsp;schaffen, die&nbsp;große Mengen an&nbsp;Informationen zusammenfasst und&nbsp;gleichzeitig gut organisiert und&nbsp;übersichtlich ist, so&nbsp;dass die&nbsp;Nutzer sie leicht durchsuchen und&nbsp;mit&nbsp;wenigen Klicks finden können, wonach sie suchen.</p>
						<p>In&nbsp;eCIM findet man innerhalb weniger Sekunden die&nbsp;sprichwörtliche Nadel im&nbsp;Heuhaufen... ehm... im&nbsp;Datensalat.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup1} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup2} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup3} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">unser Beitrag:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							Projektleitung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							Design und Architektur
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faCode} />
							</div>
							präzise SW&nbsp;Entwicklung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faServer} />
							</div>
							DevOps
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							Qualitätskontrolle
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							Unterstützung und&nbsp;Wartung
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsEcimMockup4} alt="eCIM" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">7.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">Registrierte Benutzer</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">1.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">monitorierte Unternehmen</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faMouse} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">4.500 +</div>
							<div className="ftrs-app__feedtrack-point-description">Stunden von&nbsp;Entwicklung</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
					<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">13 +</div>
							<div className="ftrs-app__feedtrack-point-description">Jahre im&nbsp;Betrieb</div>
						</div>
					</Col>
				</Row>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--dmonitor"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo4} alt="dMonitor" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--dmonitor" data-sal="fade" data-sal-duration="1000"># Medienüberwachung</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Mithilfe des Medien-Monitoring-Tools können Unternehmen die&nbsp;Marketingaktivitäten ihrer&nbsp;Mitbewerber beobachten.</h2>
						<ul className="ftrs-app__description">
							<li>Panelverwaltung</li>
							<li>Bilder</li>
							<li>Videos</li>
							<li>Soziale Netzwerke</li>
							<li>Analyse</li>
							<li>E-Mail-Benachrichtigungen</li>
							<li>Newsletter</li>
							<li>Benutzerverwaltung</li>
							<li>Inhaltskategorisierung</li>
							<li>Nutzungsstatistiken</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">Projektbeschreibung:</h2>
						<p>Im Rahmen des&nbsp;dMonitor-Projekts ist es uns gelungen, eine&nbsp;längst überfällige, aber&nbsp;sehr nützliche Legacy-App komplett zu&nbsp;aktualisieren und&nbsp;zu&nbsp;stabilisieren.</p>
						<p>Reibungslos und ohne einen einzigen Ausfall für&nbsp;die&nbsp;Nutzer haben wir die&nbsp;App komplett umgebaut und&nbsp;sogar neue Funktionen hinzugefügt.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup1} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup2} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup3} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">unser Beitrag:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital Partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							Projektleitung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							Design und Architektur
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor	">
								<FontAwesomeIcon icon={faCode} />
							</div>
							präzise SW&nbsp;Entwicklung
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faServer} />
							</div>
							DevOps
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							Qualitätskontrolle
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							Unterstützung und&nbsp;Wartung
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsDmonitorMockup4} alt="dMonitor" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

		<Footer lang="de"></Footer>

    </Layoutde>

)

export default Referenzen