import { Link } from "gatsby"
import React from "react"

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import "../header/header.scss"

import ImgFtrsLogoWhite from "../../images/ftrs-logo-white.svg"

function ftrsHeaderMobileBg() {
    var ftrsHeaderMobileElement = document.getElementById("ftrsHeaderMobile");
    if(ftrsHeaderMobileElement.classList.contains("navbar--mobile")) {
        ftrsHeaderMobileElement.classList.remove("navbar--mobile");
    }
    else {
        ftrsHeaderMobileElement.classList.add("navbar--mobile");
    }
}

const Headerde = () => (

    <header className="ftrs-header">

        <Navbar className="ftrs-header__navbar" id="ftrsHeaderMobile" expand="lg">
            <Link to="/de" className="ftrs-header__logo">
                <img
                    src={ImgFtrsLogoWhite}
                    height="40"
                    width="88"
                    className="ftrs-header__logo-image"
                    alt="Future Rockstars"
                />
            </Link>
            <Navbar.Toggle onClick={ftrsHeaderMobileBg} />
            <Navbar.Collapse>
                <Nav>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de">Startseite</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de/was-wir-tun" partiallyActive={true}>Was wir tun</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de/referenzen" partiallyActive={true}>Referenzen</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de/wir-uber-uns" partiallyActive={true}>Wir über uns</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de/karriere" partiallyActive={true}>Karriere</Link>
                    <Link className="ftrs-header__link" activeClassName="ftrs-header__link--active" to="/de/kontakt" partiallyActive={true}>Kontakt</Link>
                    <Link className="ftrs-header__link ftrs-header__link--en" to="/">EN</Link>
                    <Link className="ftrs-header__link ftrs-header__link--cs" to="/cs">CZ</Link>
                    <Link className="ftrs-header__link ftrs-header__link--de ftrs-header__link--active-lang" to="/de">DE</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    </header>

)

export default Headerde