import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Headerde from "../headerde/headerde"
import ScrollUpButton from "react-scroll-up-button"
import "../layout/layout.scss"
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const Layoutde = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleDeQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  return (
    <>
      <Headerde siteTitleDe={data.site.siteMetadata.title} />

      {children}

      <ScrollUpButton
          ContainerClassName="ftrs-back2top"
          TransitionClassName="ftrs-back2top--visible"
          ShowAtPosition={200}
      >
        <ExpandLessIcon></ExpandLessIcon>
      </ScrollUpButton> 
    </>
  )
}

Layoutde.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layoutde
